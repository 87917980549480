// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.btn {
  border: 2px solid var(--color-accent-1-light);
  border-radius: 1rem;
  padding: 0.1rem 1rem;
  position: relative;
  overflow: hidden;
  color: currentColor;

  @include media(">tablet") {
    padding: 0.1rem 2rem;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    background: var(--background-gradient);
    transform: translateX(100%);
    transition: all 0.4s ease-in-out;
  }

  &:not(&--disabled):hover,
  &:not(&--disabled):active {
    border-color: var(--color-accent-3);
  }

  &:not(&--disabled):hover::after,
  &:not(&--disabled):active::after {
    transform: translateX(0%);
  }
  &:not(&--disabled):hover > *,
  &:not(&--disabled):active > * {
    fill: var(--color-primary);
  }

  &--disabled {
    opacity: 30%;
    cursor: default;
  }
}
