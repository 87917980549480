.open-issues {
  &__item {
    display: flex;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;

    @include media(">phone") {
      flex-direction: row;
      align-items: center;
    }
  }

  &__title {
    word-break: break-all;
  }

  &__user-image {
    display: block;
    border-radius: 50%;
    background-color: var(--color-accent-1);
    width: 3rem;
    height: 3rem;
    margin-right: 2rem;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
  }

  &__description {
    font-size: 1.4rem;
    font-weight: 300;
  }
  &__right {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    font-weight: 200;
    min-width: fit-content;
    // overflow-wrap: break-word;

    @include media(">phone") {
      text-align: end;
    }

    @include media(">tablet") {
      align-self: flex-end;
    }
    @include media(">big-desktop") {
      align-self: initial;
    }
  }
  &__tags {
    font-size: 1.4rem;
  }

  &__tag {
    border: solid 1px var(--color-accent-2);
    border-radius: 1rem;
    padding: 0.1rem 0.4rem;
    display: inline-block;
    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:hover,
    &:active {
      transform: scale(1.02);
      cursor: pointer;
    }
  }

  &__date-open {
    display: none;

    @include media(">phone") {
      display: initial;
    }
  }
}
