.dropdown {
  background-color: inherit;
  z-index: 1;
  position: absolute;
  right: 0;
  top: 3rem;

  &--mobile {
    &:hover + .dropdown > .dropdown__list,
    &:active + .dropdown > .dropdown__list {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
    }

    & > .dropdown__icon {
      width: 2.5rem;
      height: 2.5rem;
      fill: var(--color-primary);

      @include media(">phone") {
        width: 3rem;
        height: 3rem;
      }
    }

    @include media(">tablet") {
      display: none;
    }
  }

  @include media(">tablet") {
    top: -0.5rem;
  }

  &__list {
    border: 1px solid var(--color-accent-1);
    border-radius: 1rem;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s ease-in;
    background-image: var(--background-gradient);

    &:hover,
    &:active {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
      cursor: pointer;
    }
  }

  &__default {
    padding: 0 1rem;
  }

  &__label {
    display: none;
    cursor: pointer;

    @include media(">tablet") {
      display: flex;
      justify-content: space-between;
      border: 1px solid var(--color-accent-1);
      border-radius: 1rem;
      padding: 0.5rem 1rem;
    }

    &:hover + .dropdown__list,
    &:active + .dropdown__list {
      display: block;
      opacity: 1;
      pointer-events: all;
      visibility: visible;
    }
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    fill: var(--color-primary);
  }

  &__item {
    text-align: end;
    border-radius: 1rem;
    padding: 0.5rem 1rem;

    &:hover,
    &:active {
      background-color: var(--color-accent-3);
      color: var(--color-background-dark-2);
    }

    &--selected {
      color: var(--color-accent-3);
    }
  }
}
