.spinner {
  margin: 5rem auto;
  text-align: center;

  svg {
    fill: var(--color-accent-3-dark);
    animation: rotate 2s infinite linear;
  }

  &--normal {
    svg {
      height: 2.5rem;
      width: 2.5rem;

      @include media(">phone") {
        height: 5rem;
        width: 5rem;
      }
    }
  }

  &--big {
    svg {
      height: 4rem;
      width: 4rem;

      @include media(">phone") {
        height: 6rem;
        width: 6rem;
      }
    }
  }
}

#gradient {
  --color-stop: var(--color-primary-1);
  --color-end: #faed34;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
