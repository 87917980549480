.header {
  --padding-sides: 5rem;

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;

  &__title {
    display: flex;
    align-items: center;

    order: 2;

    .header__img {
      height: 5rem;
      cursor: pointer;

      @include media(">tablet") {
        height: 7rem;
      }
    }

    @include media(">tablet") {
      order: 1;
      flex: 1;
    }
  }

  &--sticky {
    position: fixed;
    background-image: var(--background-gradient);
    top: 0rem;
    left: 0rem;
    right: 0rem;
    z-index: 99;
    padding: 1rem 2rem;
  }

  &__search {
    order: 1;
    @include media(">tablet") {
      order: 2;
    }
  }

  &__menu {
    order: 3;
    @include media(">tablet") {
      flex: 1;
    }
  }
}
