.sidebar {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include media(">desktop") {
    flex-direction: column;
    margin: 0;
    flex-basis: 40%;
  }

  @include media(">big-desktop") {
    flex-direction: column;
    flex-basis: 30%;
  }

  &__item {
    flex: 1;

    @include media(">desktop") {
      margin-bottom: 2rem;
    }

    &-activities {
      order: 1;
    }

    &-issues {
      order: 2;
    }
  }

  &__item > ul > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-bottom: 1px solid transparent;
    padding: 1rem 0;

    &:not(:last-child)::after {
      content: "";
      @include border-bottom-gradient;
      opacity: 50%;
      width: 50%;
      position: absolute;
      right: 25%;
    }
  }
}
