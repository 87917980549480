.repository {
  & > *:not(:last-child) {
    margin-bottom: 2rem;

    @include media(">phone") {
      margin-bottom: 3rem;
    }
  }

  &__header {
    display: inline-flex;
    align-items: center;
    border-bottom: solid 2px transparent;
    border-image: linear-gradient(90deg, var(--color-accent-1), var(--color-accent-3)) 1;
    padding-bottom: 1rem;
    gap: 1rem;
  }

  &__link {
    display: inline-block;
  }

  &__icon {
    height: 2rem;
    width: 2rem;
    fill: var(--color-secondary);
    display: block;

    @include media(">tablet") {
      // height: 3rem;
      // width: 3rem;
    }

    &--dark {
      fill: var(--color-background-dark-shade);
      height: 2.5rem;
      width: 2.5rem;

      @include media(">tablet") {
        height: 3rem;
        width: 3rem;
      }
    }
  }

  &__link:hover,
  &__link:active {
    transform: scale(1.05);
  }

  &__stats {
    display: flex;
    font-size: 1.4rem;
    font-weight: 300;
    flex-wrap: wrap;
    gap: 2rem;

    @include media(">tablet") {
      // flex-wrap: nowrap;
    }
  }
  &__block {
    $border: 2px;
    display: flex;
    flex: 0 0 100%;
    align-items: center;
    justify-content: space-around;
    // background-clip: padding-box;
    background: inherit;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
    border-radius: 1rem;
    padding: 1rem;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      border: solid $border transparent;
      background: var(--border-gradient) border-box;
      mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask-composite: destination-out;
      mask-composite: exclude;
    }

    @include media(">phone") {
      flex: 0 0 40%;
      gap: 2rem;
    }

    @include media(">desktop") {
    }

    @include media(">big-desktop") {
    }
  }
  &__element {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  &__stat {
    display: flex;
    border: solid 1px var(--color-accent-1);
    align-items: center;
    border-radius: 1rem;
    padding: 0.5rem 1rem;

    &-topics {
      word-spacing: 1rem;
    }

    &:hover,
    &:active {
    }
  }

  &__languages {
    width: 100%;
    height: 5rem;

    @include media(">tablet") {
      width: 100%;
    }

    & > canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
