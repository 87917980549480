.chart {
  &__header {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    margin-bottom: 2rem;
  }

  &__wrapper {
    height: 40vh;

    @include media(">big-desktop") {
      height: 50vh;
    }

    & > canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &__info {
    position: relative;
    // width: 100%;
    &-icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--color-secondary-light);

      &:hover + .chart__info-message,
      &:active + .chart__info-message {
        opacity: 1;
      }
    }

    &-message {
      font-size: 1.2rem;
      font-weight: 300;
      opacity: 1;
      border: 1px solid var(--color-secondary-light);
      border-radius: 1rem;
      position: absolute;
      // top: -4rem;
      // right: -15rem;
      top: 0;
      left: 2rem;
      white-space: nowrap;
      padding: 0.5rem 1rem;
      transition: all 0.5s ease-out;

      opacity: 0;
    }
  }
}
