.activities {
  &__item {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }

  &__user-image {
    border-radius: 50%;
    background-color: var(--color-accent-1);
    width: 3rem;
    height: 3rem;
  }

  &__user {
    display: flex;
    gap: 2rem;
    align-items: center;

    @include media(">phone") {
      flex: 1;
    }
  }

  &__name {
    display: none;

    @include media(">phone") {
      display: initial;
    }
  }

  &__type {
    color: var(--color-background-dark-shade);
    font-weight: 400;
    flex: 1;
  }
  &__date {
    font-size: 1rem;
    font-weight: 200;
    text-align: end;
    // flex: 1;
  }
}
