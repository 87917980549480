// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

* {
  margin: 0;
  padding: 0;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  background-image: var(--background-gradient);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 8px;

  @include media(">phone") {
    padding: 2rem 2rem;
  }

  @include media(">desktop") {
    padding: 2rem 4rem;
  }

  @include media(">big-desktop") {
    padding: 2rem 8rem;
  }

  @include media(">xl") {
    padding: 2rem 12rem;
  }
}

body:has(.header--sticky) main {
  margin-top: 10rem;
}

ul {
  list-style: none;
}

select {
  appearance: none;
  border: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  color: inherit;
  background-color: transparent;
}

option {
  appearance: none;
  color: inherit;
  background-color: var(--color-accent-1);
}

a {
  color: inherit;
  text-decoration: inherit;
}

.link--block {
  flex: 1;

  &:hover,
  &:active {
    transform: scale(1.02);
  }
}
