// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

main {
  display: flex;
  justify-content: center;
  @include media(">tablet") {
    flex: 0 0 85%;
  }

  @include media(">desktop") {
    flex: 0 0 75%;
  }

  @include media(">big-desktop") {
    flex: 0 0 65%;
  }
}

// repository 'page'

.repository-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;

  @include media(">desktop") {
    flex-direction: row;
    gap: 6rem;
    justify-content: space-between;
  }
}
//search result 'page'

// .searchresult-container {
//   flex: 1;
//   display: none;

//   @include media(">desktop") {
//     display: flex;
//     justify-content: center;
//   }
// }
