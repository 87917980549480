.searchresult {
  display: flex;
  flex-direction: column;
  scroll-margin-top: 5rem;

  @include media(">tablet") {
    flex-basis: 75%;
  }

  @include media(">desktop") {
    display: flex;
    justify-content: center;
    width: 65%;
    flex-basis: 65%;
  }

  &__info {
    display: flex;
    font-size: 1.4rem;
    font-weight: 300;
    margin-bottom: 2rem;
    position: relative;
    justify-content: space-between;

    @include media(">tablet") {
      margin-bottom: 4rem;
    }
  }

  &__language {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    &-icon {
      width: 4rem;
      height: 4rem;
    }

    &-text {
      display: none;
      @include media(">phone") {
        display: unset;
      }
    }
  }

  &__item {
    display: flex;
    position: relative;
    justify-content: space-between;
    border-bottom: 1px solid transparent;
    margin-bottom: 1rem;

    gap: 1.5rem;
    flex-wrap: wrap;
    cursor: pointer;

    &:not(:last-child)::after {
      content: "";
      @include border-bottom-gradient;
      width: 100%;
      position: absolute;
    }

    &-center {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-basis: 100%;
      gap: 1rem;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.6rem;
    width: 100%;
    word-break: break-word;
  }
  &__description {
    font-weight: 300;
    text-align: left;
    word-break: break-word;
  }

  &__repository-info {
    gap: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;

    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include media(">phone") {
      gap: 4rem;
    }
  }
}
