:root {
  --color-background-dark-shade: #5b7c99;
  --color-secondary: #f37f19;
  --color-secondary-dark: #d5690b;
  --color-secondary-light: #f37f19bf;
  --color-accent-1: #034f77;
  --color-accent-3: #25aef8;
  --color-accent-3-dark: #0790da;
  --color-accent-2: #103888;
  --margin-body: 2rem;
  --border-gradient: linear-gradient(125deg, var(--color-accent-1) 0%, var(--color-accent-2) 50%, var(--color-accent-3) 100%);
  --background-gradient: linear-gradient(to right bottom, var(--color-background-dark-1) 0%, var(--color-background-dark-2) 100%);
  --border-radius: 1rem;
}

:root[data-theme="light"] {
  --color-primary: #011018;
  --color-text-primary: #011018;
  --color-accent-1-light: #022f47;
  --color-accent-1-dark: #25aef880;
  --color-accent-3: #25aef8;
  --color-background-dark-1: #e2eaf4;
  --color-background-dark-2: #98b7d7;
}

:root[data-theme="dark"] {
  --color-primary: #d3e0ee;
  --color-text-primary: #d3e0ee;
  --color-accent-1-light: #25aef880;
  --color-accent-1-dark: #022f47;
  --color-background-dark-1: #022537;
  --color-background-dark-2: #011018;
}

* {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  min-height: 100vh;
  background-image: var(--background-gradient);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 8px;
}

@media (min-width: 641px) {
  body {
    padding: 2rem;
  }
}

@media (min-width: 1025px) {
  body {
    padding: 2rem 4rem;
  }
}

@media (min-width: 1281px) {
  body {
    padding: 2rem 8rem;
  }
}

@media (min-width: 1537px) {
  body {
    padding: 2rem 12rem;
  }
}

body:has(.header--sticky) main {
  margin-top: 10rem;
}

ul {
  list-style: none;
}

select {
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  color: inherit;
  background-color: #0000;
  border: none;
  outline: none;
  margin: 0;
}

option {
  appearance: none;
  color: inherit;
  background-color: var(--color-accent-1);
}

a {
  color: inherit;
  text-decoration: inherit;
}

.link--block {
  flex: 1;
}

.link--block:hover, .link--block:active {
  transform: scale(1.02);
}

body {
  color: var(--color-text-primary);
  font-family: Poppins, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
}

.heading-secondary, .heading-tertiary {
  background: -o-linear-gradient(325deg, var(--color-accent-1) 0%, var(--color-accent-3) 100%);
  background: linear-gradient(125deg, var(--color-accent-1) 0%, var(--color-accent-3) 100%);
  color: #0000;
  -webkit-background-clip: text;
  text-transform: capitalize;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
}

@media (min-width: 1537px) {
  .heading-secondary, .heading-tertiary {
    font-size: 150%;
  }
}

h4 {
  font-size: 1.5rem;
  font-weight: 400;
}

@media (min-width: 1537px) {
  h4 {
    font-size: 1.7rem;
  }
}

.repository__title {
  letter-spacing: .1rem;
  font-size: 2rem;
  font-weight: 500;
}

@media (min-width: 1537px) {
  .repository__title {
    font-size: 2.5rem;
  }
}

.activities__item {
  font-size: 1.4rem;
}

@media (min-width: 1537px) {
  .activities__item {
    font-size: 1.6rem;
  }
}

.info-message {
  text-align: center;
}

.header {
  --padding-sides: 5rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  display: flex;
  position: relative;
}

.header__title {
  order: 2;
  align-items: center;
  display: flex;
}

.header__title .header__img {
  height: 5rem;
  cursor: pointer;
}

@media (min-width: 769px) {
  .header__title .header__img {
    height: 7rem;
  }

  .header__title {
    flex: 1;
    order: 1;
  }
}

.header--sticky {
  background-image: var(--background-gradient);
  z-index: 99;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header__search {
  order: 1;
}

@media (min-width: 769px) {
  .header__search {
    order: 2;
  }
}

.header__menu {
  order: 3;
}

@media (min-width: 769px) {
  .header__menu {
    flex: 1;
  }
}

.repository > :not(:last-child) {
  margin-bottom: 2rem;
}

@media (min-width: 641px) {
  .repository > :not(:last-child) {
    margin-bottom: 3rem;
  }
}

.repository__header {
  border-bottom: 2px solid #0000;
  -o-border-image: -o-linear-gradient(left, var(--color-accent-1), var(--color-accent-3)) 1;
  border-image: -webkit-gradient(linear, left top, right top, from(var(--color-accent-1)), to(var(--color-accent-3))) 1;
  border-image: linear-gradient(90deg, var(--color-accent-1), var(--color-accent-3)) 1;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
  display: inline-flex;
}

.repository__link {
  display: inline-block;
}

.repository__icon {
  height: 2rem;
  width: 2rem;
  fill: var(--color-secondary);
  display: block;
}

.repository__icon--dark {
  fill: var(--color-background-dark-shade);
  height: 2.5rem;
  width: 2.5rem;
}

@media (min-width: 769px) {
  .repository__icon--dark {
    height: 3rem;
    width: 3rem;
  }
}

.repository__link:hover, .repository__link:active {
  transform: scale(1.05);
}

.repository__stats {
  flex-wrap: wrap;
  gap: 2rem;
  font-size: 1.4rem;
  font-weight: 300;
  display: flex;
}

.repository__block {
  background: inherit;
  letter-spacing: .1rem;
  border-radius: 1rem;
  flex: 0 0 100%;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  position: relative;
}

.repository__block:before {
  content: "";
  border-radius: inherit;
  background: var(--border-gradient) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: destination-out;
  -webkit-mask-composite: xor;
  border: 2px solid #0000;
  position: absolute;
  inset: 0;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  mask-repeat: repeat, repeat;
  -webkit-mask-clip: padding-box, border-box;
  mask-clip: padding-box, border-box;
  -webkit-mask-origin: padding-box, border-box;
  mask-origin: padding-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-source-type: auto, auto;
  mask-mode: match-source, match-source;
}

@media (min-width: 641px) {
  .repository__block {
    flex: 0 0 40%;
    gap: 2rem;
  }
}

.repository__element {
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.repository__stat {
  border: solid 1px var(--color-accent-1);
  border-radius: 1rem;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
}

.repository__stat-topics {
  word-spacing: 1rem;
}

.repository__languages {
  width: 100%;
  height: 5rem;
}

@media (min-width: 769px) {
  .repository__languages {
    width: 100%;
  }
}

.repository__languages > canvas {
  width: 100% !important;
  height: 100% !important;
}

.sidebar {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

@media (min-width: 1025px) {
  .sidebar {
    flex-direction: column;
    flex-basis: 40%;
    margin: 0;
  }
}

@media (min-width: 1281px) {
  .sidebar {
    flex-direction: column;
    flex-basis: 30%;
  }
}

.sidebar__item {
  flex: 1;
}

@media (min-width: 1025px) {
  .sidebar__item {
    margin-bottom: 2rem;
  }
}

.sidebar__item-activities {
  order: 1;
}

.sidebar__item-issues {
  order: 2;
}

.sidebar__item > ul > li {
  border-bottom: 1px solid #0000;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  display: flex;
  position: relative;
}

.sidebar__item > ul > li:not(:last-child):after {
  content: "";
  -o-border-image: var(--border-gradient) 2;
  border-image: var(--border-gradient) 2;
  opacity: .5;
  width: 50%;
  border-bottom: 1px solid;
  position: absolute;
  bottom: 0;
  right: 25%;
}

.main-content {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

@media (min-width: 641px) {
  .main-content {
    width: 100%;
    gap: 3rem;
  }
}

@media (min-width: 1025px) {
  .main-content {
    flex-basis: 60%;
    gap: 3rem;
  }
}

button {
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
  padding: 0;
}

.btn {
  border: 2px solid var(--color-accent-1-light);
  color: currentColor;
  border-radius: 1rem;
  padding: .1rem 1rem;
  position: relative;
  overflow: hidden;
}

@media (min-width: 769px) {
  .btn {
    padding: .1rem 2rem;
  }
}

.btn:after {
  content: "";
  z-index: -1;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  background: var(--background-gradient);
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.btn:not(.btn--disabled):hover, .btn:not(.btn--disabled):active {
  border-color: var(--color-accent-3);
}

.btn:not(.btn--disabled):hover:after, .btn:not(.btn--disabled):active:after {
  transform: translateX(0%);
}

.btn:not(.btn--disabled):hover > *, .btn:not(.btn--disabled):active > * {
  fill: var(--color-primary);
}

.btn--disabled {
  opacity: .3;
  cursor: default;
}

@media (min-width: 769px) {
  .searchbar-container {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .searchbar-container:focus-within {
    width: 100%;
  }

  .searchbar-container:focus-within ~ * {
    display: none;
  }
}

.searchbar-container--home {
  --search-height: 4rem;
  width: 95%;
  height: var(--search-height);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 769px) {
  .searchbar-container--home {
    width: 50%;
  }
}

.searchbar-container--home > .search {
  border: 1px solid var(--color-accent-1);
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.searchbar-container--home > .search > .search__input {
  opacity: 1;
  text-align: center;
  font-size: 1.8rem;
  display: flex;
}

.search {
  --size: 3rem;
  --search-height: 4rem;
  height: calc(var(--size)  + 4px);
  width: calc(var(--size)  + 4px);
  height: var(--search-height);
  -o-transition: width .4s ease-out;
  transition: width .4s ease-out;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 769px) {
  .search {
    border: 1px solid var(--color-accent-1);
    height: auto;
    width: auto;
    border-radius: 1rem;
    align-items: center;
    padding: .5rem;
  }
}

.search__input {
  color: var(--color-text-primary);
  opacity: 0;
  z-index: 20;
  cursor: pointer;
  text-align: center;
  background-color: #0000;
  border: none;
  outline: 0;
  flex-grow: 1;
  padding: 0 1rem;
  font-size: 1.6rem;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (min-width: 769px) {
  .search__input {
    opacity: 1;
    cursor: initial;
    font-size: 1.8rem;
    position: relative;
  }
}

.search__input::-webkit-input-placeholder {
  color: currentColor;
  opacity: .5;
  text-align: center;
}

.search__input::-moz-placeholder {
  color: currentColor;
  opacity: .5;
  text-align: center;
}

.search__input:-ms-input-placeholder {
  color: currentColor;
  opacity: .5;
  text-align: center;
}

.search__input::placeholder {
  color: currentColor;
  opacity: .5;
  text-align: center;
}

.search__icon {
  width: var(--size);
  height: var(--size);
  fill: var(--color-primary);
}

.search__icon:hover, .search__icon:active {
  fill: var(--color-accent-3);
}

.search__button {
  cursor: pointer;
  z-index: 15;
  align-items: center;
  margin-left: auto;
  display: flex;
}

@media (min-width: 769px) {
  .search__button {
    display: flex;
  }
}

.search:focus-within {
  width: 100%;
  border: 1px solid var(--color-accent-1-light);
  z-index: 5;
  background-image: var(--background-gradient);
  border-radius: 1rem;
}

.search:focus-within .search__input {
  opacity: 1;
  width: calc(100% - var(--size)  - 1rem);
  cursor: initial;
}

@media (min-width: 769px) {
  .search:focus-within {
    background-image: initial;
  }
}

.chart__header {
  justify-content: center;
  gap: .5rem;
  margin-bottom: 2rem;
  display: flex;
}

.chart__wrapper {
  height: 40vh;
}

@media (min-width: 1281px) {
  .chart__wrapper {
    height: 50vh;
  }
}

.chart__wrapper > canvas {
  width: 100% !important;
  height: 100% !important;
}

.chart__info {
  position: relative;
}

.chart__info-icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--color-secondary-light);
}

.chart__info-icon:hover + .chart__info-message, .chart__info-icon:active + .chart__info-message {
  opacity: 1;
}

.chart__info-message {
  opacity: 1;
  border: 1px solid var(--color-secondary-light);
  white-space: nowrap;
  -o-transition: all .5s ease-out;
  opacity: 0;
  border-radius: 1rem;
  padding: .5rem 1rem;
  font-size: 1.2rem;
  font-weight: 300;
  transition: all .5s ease-out;
  position: absolute;
  top: 0;
  left: 2rem;
}

.open-issues__item {
  align-items: center;
  display: flex;
}

.open-issues__content {
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  display: flex;
}

@media (min-width: 641px) {
  .open-issues__content {
    flex-direction: row;
    align-items: center;
  }
}

.open-issues__title {
  word-break: break-all;
}

.open-issues__user-image {
  background-color: var(--color-accent-1);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 2rem;
  display: block;
}

.open-issues__left {
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  display: flex;
}

.open-issues__description {
  font-size: 1.4rem;
  font-weight: 300;
}

.open-issues__right {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  flex-direction: column;
  font-size: 1.2rem;
  font-weight: 200;
  display: flex;
}

@media (min-width: 641px) {
  .open-issues__right {
    text-align: end;
  }
}

@media (min-width: 769px) {
  .open-issues__right {
    align-self: flex-end;
  }
}

@media (min-width: 1281px) {
  .open-issues__right {
    -ms-flex-item-align: initial;
    align-self: initial;
  }
}

.open-issues__tags {
  font-size: 1.4rem;
}

.open-issues__tag {
  border: solid 1px var(--color-accent-2);
  border-radius: 1rem;
  padding: .1rem .4rem;
  display: inline-block;
}

.open-issues__tag:not(:last-child) {
  margin-right: 1rem;
}

.open-issues__tag:hover, .open-issues__tag:active {
  cursor: pointer;
  transform: scale(1.02);
}

.open-issues__date-open {
  display: none;
}

@media (min-width: 641px) {
  .open-issues__date-open {
    display: initial;
  }
}

.activities__item {
  justify-content: space-between;
  gap: 2rem;
  display: flex;
}

.activities__user-image {
  background-color: var(--color-accent-1);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.activities__user {
  align-items: center;
  gap: 2rem;
  display: flex;
}

@media (min-width: 641px) {
  .activities__user {
    flex: 1;
  }
}

.activities__name {
  display: none;
}

@media (min-width: 641px) {
  .activities__name {
    display: initial;
  }
}

.activities__type {
  color: var(--color-background-dark-shade);
  flex: 1;
  font-weight: 400;
}

.activities__date {
  text-align: end;
  font-size: 1rem;
  font-weight: 200;
}

.searchresult {
  flex-direction: column;
  scroll-margin-top: 5rem;
  display: flex;
}

@media (min-width: 769px) {
  .searchresult {
    flex-basis: 75%;
  }
}

@media (min-width: 1025px) {
  .searchresult {
    width: 65%;
    flex-basis: 65%;
    justify-content: center;
    display: flex;
  }
}

.searchresult__info {
  justify-content: space-between;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  font-weight: 300;
  display: flex;
  position: relative;
}

@media (min-width: 769px) {
  .searchresult__info {
    margin-bottom: 4rem;
  }
}

.searchresult__language {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.searchresult__language-icon {
  width: 4rem;
  height: 4rem;
}

.searchresult__language-text {
  display: none;
}

@media (min-width: 641px) {
  .searchresult__language-text {
    display: unset;
  }
}

.searchresult__item {
  cursor: pointer;
  border-bottom: 1px solid #0000;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  position: relative;
}

.searchresult__item:not(:last-child):after {
  content: "";
  -o-border-image: var(--border-gradient) 2;
  border-image: var(--border-gradient) 2;
  width: 100%;
  border-bottom: 1px solid;
  position: absolute;
  bottom: 0;
}

.searchresult__item-center {
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.searchresult__title {
  width: 100%;
  word-break: break-word;
  font-size: 1.6rem;
  font-weight: 500;
}

.searchresult__description {
  text-align: left;
  word-break: break-word;
  font-weight: 300;
}

.searchresult__repository-info {
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.searchresult__repository-info > * {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 641px) {
  .searchresult__repository-info {
    gap: 4rem;
  }
}

.pagination {
  --background-gradient: linear-gradient(45deg, #25aef84d 0%, #034f774d 100%);
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: .1rem 1rem;
  display: flex;
}

.pagination__current-page {
  word-spacing: .2rem;
  font-size: 1.4rem;
  font-weight: 300;
}

.pagination__next:after {
  transform: translateX(-100%);
}

.pagination__icon {
  width: 2.5rem;
  height: 2.5rem;
  fill: var(--color-accent-3);
  margin: auto;
  display: block;
}

.dropdown {
  background-color: inherit;
  z-index: 1;
  position: absolute;
  top: 3rem;
  right: 0;
}

.dropdown--mobile:hover + .dropdown > .dropdown__list, .dropdown--mobile:active + .dropdown > .dropdown__list {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.dropdown--mobile > .dropdown__icon {
  width: 2.5rem;
  height: 2.5rem;
  fill: var(--color-primary);
}

@media (min-width: 641px) {
  .dropdown--mobile > .dropdown__icon {
    width: 3rem;
    height: 3rem;
  }
}

@media (min-width: 769px) {
  .dropdown--mobile {
    display: none;
  }

  .dropdown {
    top: -.5rem;
  }
}

.dropdown__list {
  border: 1px solid var(--color-accent-1);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  -o-transition: all .5s ease-in;
  background-image: var(--background-gradient);
  border-radius: 1rem;
  transition: all .5s ease-in;
}

.dropdown__list:hover, .dropdown__list:active {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  cursor: pointer;
}

.dropdown__default {
  padding: 0 1rem;
}

.dropdown__label {
  cursor: pointer;
  display: none;
}

@media (min-width: 769px) {
  .dropdown__label {
    border: 1px solid var(--color-accent-1);
    border-radius: 1rem;
    justify-content: space-between;
    padding: .5rem 1rem;
    display: flex;
  }
}

.dropdown__label:hover + .dropdown__list, .dropdown__label:active + .dropdown__list {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  display: block;
}

.dropdown__icon {
  width: 2rem;
  height: 2rem;
  fill: var(--color-primary);
}

.dropdown__item {
  text-align: end;
  border-radius: 1rem;
  padding: .5rem 1rem;
}

.dropdown__item:hover, .dropdown__item:active {
  background-color: var(--color-accent-3);
  color: var(--color-background-dark-2);
}

.dropdown__item--selected {
  color: var(--color-accent-3);
}

.spinner {
  text-align: center;
  margin: 5rem auto;
}

.spinner svg {
  fill: var(--color-accent-3-dark);
  animation: 2s linear infinite rotate;
}

.spinner--normal svg {
  height: 2.5rem;
  width: 2.5rem;
}

@media (min-width: 641px) {
  .spinner--normal svg {
    height: 5rem;
    width: 5rem;
  }
}

.spinner--big svg {
  height: 4rem;
  width: 4rem;
}

@media (min-width: 641px) {
  .spinner--big svg {
    height: 6rem;
    width: 6rem;
  }
}

#gradient {
  --color-stop: var(--color-primary-1);
  --color-end: #faed34;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.menu__button {
  z-index: 2;
  flex-direction: column;
  display: flex;
  position: relative;
}

.menu__button > * {
  --width: 2.5rem;
  width: var(--width);
  height: 2px;
  background-color: var(--color-primary);
  -o-transition: all .4s ease-out;
  transition: all .4s ease-out;
}

.menu__button > :nth-child(1) {
  transform: translateY(-1rem);
}

.menu__button > :nth-child(3) {
  transform: translateY(1rem);
}

.menu__button--open > :nth-child(1) {
  transform: rotate(-45deg);
}

.menu__button--open > :nth-child(2) {
  display: none;
}

.menu__button--open > :nth-child(3) {
  transform: rotate(45deg);
}

@media (min-width: 769px) {
  .menu__button {
    display: none;
  }
}

.menu__darkmode-btn {
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
}

.menu__darkmode-btn:hover > .menu__darkmode-icon, .menu__darkmode-btn:active > .menu__darkmode-icon {
  fill: var(--color-secondary-dark);
}

.menu__darkmode-btn:hover > .menu__darkmode-icon--dark, .menu__darkmode-btn:active > .menu__darkmode-icon--dark {
  fill: var(--color-accent-3-dark);
}

.menu__darkmode-icon {
  width: 2.5rem;
  height: 2.5rem;
  opacity: 0;
  -o-transition: transform .4s cubic-bezier(.05, .91, .39, 1.52);
  transition: transform .4s cubic-bezier(.05, .91, .39, 1.52), -webkit-transform .4s cubic-bezier(.05, .91, .39, 1.52);
  position: absolute;
  top: 0;
  left: 0;
}

.menu__darkmode-icon--light {
  fill: var(--color-secondary);
  transform: translateY(100%);
}

.menu__darkmode-icon--dark {
  fill: var(--color-accent-3);
  transform: translateY(-100%);
}

.menu__darkmode-icon--active {
  opacity: 1;
  transform: translateY(0);
}

.menu__content {
  height: 100vh;
  width: 100%;
  z-index: 1;
  background-image: var(--background-gradient);
  -o-transition: translate 1s ease-in;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding: 10rem;
  font-size: 2rem;
  transition: translate 1s ease-in;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 769px) {
  .menu__content {
    height: initial;
    background-image: initial;
    padding: initial;
    flex-direction: row-reverse;
    gap: 5rem;
    display: flex;
    position: relative;
    transform: translateX(0);
  }
}

.menu__content--open {
  display: flex;
  transform: translateX(0);
}

.menu__links > :after {
  content: "";
  width: 0;
  -o-transition: all .4s ease;
  border-bottom: 1px solid;
  margin: auto;
  transition: all .4s;
  display: block;
}

.menu__links > :hover:after {
  width: 100%;
  border-bottom: 1px solid;
}

.menu__links > :hover, .menu__links > :active {
  color: var(--color-accent-3);
  cursor: pointer;
}

main {
  justify-content: center;
  display: flex;
}

@media (min-width: 769px) {
  main {
    flex: 0 0 85%;
  }
}

@media (min-width: 1025px) {
  main {
    flex: 0 0 75%;
  }
}

@media (min-width: 1281px) {
  main {
    flex: 0 0 65%;
  }
}

.repository-container {
  flex-direction: column;
  flex: 1;
  gap: 2rem;
  display: flex;
}

@media (min-width: 1025px) {
  .repository-container {
    flex-direction: row;
    justify-content: space-between;
    gap: 6rem;
  }
}

/*# sourceMappingURL=index.7e7642e2.css.map */
