:root {
  --color-background-dark-shade: #5b7c99;
  --color-secondary: #f37f19;
  --color-secondary-dark: #d5690b;
  --color-secondary-light: rgba(243, 127, 25, 75%);
  --color-accent-1: #034f77;
  --color-accent-3: #25aef8;
  --color-accent-3-dark: #0790da;
  --color-accent-2: #103888;
  --margin-body: 2rem;
  --border-gradient: linear-gradient(125deg, var(--color-accent-1) 0%, var(--color-accent-2) 50%, var(--color-accent-3) 100%);
  --background-gradient: linear-gradient(to right bottom, var(--color-background-dark-1) 0%, var(--color-background-dark-2) 100%);

  --border-radius: 1rem;
}

:root[data-theme="light"] {
  --color-primary: #011018;
  --color-text-primary: #011018;

  --color-accent-1-light: #022f47;
  --color-accent-1-dark: #25aef880;

  --color-accent-3: #25aef8;

  --color-background-dark-1: #e2eaf4;
  --color-background-dark-2: #98b7d7;
}

:root[data-theme="dark"] {
  --color-primary: #d3e0ee;
  --color-text-primary: #d3e0ee;

  --color-accent-1-light: #25aef880;
  --color-accent-1-dark: #022f47;

  --color-background-dark-1: #022537;
  --color-background-dark-2: #011018;
}
