/**
 * Basic typography style for copy text
 */
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  font-size: 1.6rem;
}

.heading-secondary,
.heading-tertiary {
  background: linear-gradient(125deg, var(--color-accent-1) 0%, var(--color-accent-3) 100%);
  // background: conic-gradient(from 0 at 25% 25%, #f5d6a3 0, #e69c24 360deg);
  color: transparent;
  background-clip: text;

  // background-image: conic-gradient(var(--color-secondary), var(--color-secondary-light-2));
  // background-image: conic-gradient(from 180deg at 50% 50%, #e69c24 0deg, #fff 360deg);
  // background-size: 100%;
  // background-repeat: repeat;
  // color: var(--color-secondary-light-2);

  text-transform: capitalize;
  text-align: center;

  @include media(">xl") {
    font-size: 150%;
  }
}

h4 {
  font-size: 1.5rem;
  font-weight: 400;

  @include media(">xl") {
    font-size: 1.7rem;
  }
}

.repository__title {
  font-weight: 500;
  letter-spacing: 0.1rem;
  font-size: 2rem;

  @include media(">xl") {
    font-size: 2.5rem;
  }
}

.activities__item {
  font-size: 1.4rem;

  @include media(">xl") {
    font-size: 1.6rem;
  }
}

.info-message {
  text-align: center;
}
