.searchbar-container {
  @include media(">tablet") {
    width: 50%;
  }

  &:focus-within {
    @include media("<tablet") {
      width: 100%;

      & ~ * {
        display: none;
      }
    }
  }

  &--home {
    --search-height: 4rem;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: var(--search-height);

    @include media(">tablet") {
      width: 50%;
    }

    & > .search {
      border-radius: 1rem;
      border: 1px solid var(--color-accent-1);
      align-items: center;
      justify-content: center;
      display: flex;
      height: 100%;
      width: 100%;

      & > .search__input {
        display: flex;
        opacity: 1;
        font-size: 1.8rem;
        text-align: center;
      }
    }
  }
}

.search {
  --size: 3rem;
  --search-height: 4rem;

  display: flex;
  height: calc(var(--size) + 4px);
  width: calc(var(--size) + 4px);
  height: var(--search-height);

  position: relative;
  transition: width 0.4s ease-out;
  overflow: hidden;

  @include media(">tablet") {
    border-radius: 1rem;
    border: 1px solid var(--color-accent-1);
    align-items: center;
    height: auto;
    width: auto;
    padding: 0.5rem;
  }

  &__input {
    flex-grow: 1;
    color: var(--color-text-primary);
    background-color: transparent;
    border: none;
    padding: 0 1rem;
    position: absolute;
    top: 0;
    bottom: 0;
    outline: 0;
    opacity: 0;
    z-index: 20;
    cursor: pointer;
    font-size: 1.6rem;
    text-align: center;

    @include media(">tablet") {
      opacity: 1;
      position: relative;
      cursor: initial;
      font-size: 1.8rem;
    }

    &::placeholder {
      color: currentColor;
      opacity: 50%;
      text-align: center;
    }
  }

  &__icon {
    width: var(--size);
    height: var(--size);
    fill: var(--color-primary);

    &:hover,
    &:active {
      fill: var(--color-accent-3);
    }
  }

  &__button {
    margin-left: auto;
    cursor: pointer;
    z-index: 15;
    display: flex;
    align-items: center;

    @include media(">tablet") {
      display: flex;
    }
  }

  &:focus-within {
    width: 100%;
    border-radius: 1rem;
    border: 1px solid var(--color-accent-1-light);
    z-index: 5;
    background-image: var(--background-gradient);

    .search__input {
      opacity: 1;
      width: calc(100% - var(--size) - 1rem);
      cursor: initial;
    }

    @include media(">tablet") {
      background-image: initial;
    }
  }
}
